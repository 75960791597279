import React, { Fragment } from "react";
import "../assets/sass/style.scss";

import Seo from "../blocks/seo/Seo";

import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleGeneric from "../blocks/page-title/PageTitleGeneric";
import BlockContact from "../blocks/contact/BlockContact";
import Gallery from "../blocks/gallery/Gallery";

const Projects = () => {
  return (
    <Fragment>
      <Seo
        title="Projects"
        keywords="projects keywords"
        bodyAttributes={{
          class: "archive bg-fixed bg-line",
        }}
      />

      <Header />

      <main id="main" className="site-main">
        <PageTitleGeneric title="Projects" />

        <section id="page-content" className="spacer p-top-xl">
          <Gallery />
          <BlockContact goalId="TFSC73IB" />
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Projects;
